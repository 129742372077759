<template>
  <div>
    <h1 class="text-gray-600 dark:text-gray-300">{{ $t('choose_name') }}</h1>
    <form class="mt-6" @submit.prevent="handleSubmit">
      <text-field
        ref="workspace"
        :bottom-hint="$t('something_easy')"
        :label="$t('name')"
        :model-value="workspaceName"
        required
        :error="nameError"
        :limit="getMaxLength"
        @update:model-value="setWorkspaceName"
      />
    </form>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapState } = createNamespacedHelpers('newWorkspace');
import componentI18n from '@/i18n/componentI18n';
import { TEAM_CONSTANTS } from '@/constants/index';

export default {
  ...componentI18n('components.workspaces.workspace_form'),
  computed: {
    ...mapState(['workspaceName']),
    ...mapGetters(['canAdvanceWorkspaceStep', 'workspaceNameHasValidLength']),
    nameError() {
      return this.workspaceNameHasValidLength ? null : this.$t('errors.name.too_long');
    },
    getMaxLength() {
      return TEAM_CONSTANTS.MAX_NAME_LENGTH;
    }
  },
  mounted() {
    this.$refs.workspace.$el.focus();
  },
  methods: {
    ...mapMutations(['incrementStep', 'setWorkspaceName']),
    handleSubmit() {
      if (this.canAdvanceWorkspaceStep) {
        this.incrementStep();
      }
    }
  }
};
</script>
